<template>
  <div
    v-if="getSearchFlightArray"
    class="py-50 px-0 rounded-lg mb-1"
    style="background-color: #F5F5F5"
  >
    <div
      v-for="(flight, indexFlight) of getSearchFlightArray"
      :key="indexFlight"
      class="mb-75"
    >
      <div
        class="rounded-lg py-75 pl-2 fw-700 mb-50"
        style="background-color: #166987; color: white;"
      >
        Hành trình #{{ indexFlight + 1 }}:
        <span class="fw-700">{{ `${flight.startPoint}-${flight.endPoint}` }}</span>
      </div>

      <template v-if="selectedTrips[indexFlight]">
        <!-- ANCHOR - flight has selected -->
        <b-row
          v-for="(segment, indexSegment) in selectedTrips[indexFlight]"
          :key="indexSegment"
          class="mb-75 d-flex"
          no-gutters
        >
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center"
          >
            <span
              :class="`mr-25 mr-md-50 font-weight-bolder
              ${['xs','sm','md'].includes(appBreakPoint) ? 'font-small-4' : 'font-medium-1'}`"
            >
              {{ `${indexFlight + 1}*${indexSegment + 1}` }}
            </span>

            <!-- ANCHOR - Info trip -->
            <b-form-input
              v-if="segment"
              :value="`${getSortTripBySegment(segment, true)}`"
              :class="`text-body fw-700
              ${['xs','sm','md'].includes(appBreakPoint) ? 'font-medium-1' : 'font-medium-2'}
              `"
              style="min-width: 300px;"
              disabled
            />
            <b-form-input
              v-else
              :value="`Chưa chọn chặng ${indexSegment + 1}`"
              class="text-warning font-italic"
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-around pl-md-1 mt-50 mt-md-0"
          >
            <!-- ANCHOR - bookingClassCode -->
            <v-select
              v-if="segment && bookingClassCodeSegment[indexFlight] && bookingClassCodeSegment[indexFlight][indexSegment]"
              v-model="bookingClassCodeSegment[indexFlight][indexSegment]"
              :options="classBookingOptions"
              :clearable="false"
              :searchable="false"
              class="select_custom fw-700"
              style="width: 70px !important; background-color: white;"
              @option:selected="(val) => handleChangeClassBooking(val, indexFlight, segment, indexSegment)"
            />
            <!-- FIXME - chỗ này sửa lại nếu chọn vào class nó không có trên hành trình thì không thay đổi classBooking trong store -->
            <b-form-input
              v-else
              style="width: 70px !important;"
              value=""
              disabled
            />

            <!-- ANCHOR - Price total trip -->
            <b-form-input
              v-if="segment"
              :value="`${segment.bookingClass.prices[0].fare && dataTrips[indexFlight] && dataTrips[indexFlight].isDomestic ? formatCurrency(segment.bookingClass.prices[0].fare) : '0'}`"
              class="text-body fw-700 font-medium-2"
              style="width: 140px;"
              disabled
            />
            <b-form-input
              v-else
              value=""
              class="text-warning font-italic"
              style="width: 80px !important;"
              disabled
            />

            <!-- ANCHOR - statusSegment -->
            <v-select
              v-if="segment && statusSegment[indexFlight] && statusSegment[indexFlight][indexSegment]"
              v-model="statusSegment[indexFlight][indexSegment]"
              :options="['NN','LL','DS']"
              :clearable="false"
              :searchable="false"
              class="select_custom fw-700"
              style="width: 80px !important; background-color: white;"
              @input="(val) => handleChangeStatus(val, indexFlight, indexSegment)"
            />
            <b-form-input
              v-else
              style="width: 80px !important;"
              value=""
              disabled
            />
          </b-col>
        </b-row>
      </template>

      <b-form-input
        v-else
        value="Chưa chọn chuyến bay"
        class="text-danger font-italic"
        :style="`${isMobileView ? '' : `max-width: 60% !important`}`"
        disabled
      />
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import useBookingHandle from '@flightv2/useBookingHandle'
import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    getSearchFlightArray: {
      type: Array,
      default: () => [],
    },
    selectedTrips: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
    } = useBookingHandle()

    const {
      store,
      isEmpty,
      cloneDeep,
      dataTrips,
      toastError,
      formatCurrency,
      convertISODateTime,
      classBookingOptions,
      handleSelectClassBooking,
      getSortTripBySegment,
    } = useClassBookingHandle()

    const statusSegment = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusClassBooking`]))
    const bookingClassCodeSegment = ref(computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getBookingClassCode`]))

    function handleChangeStatus(value, indexFlight, indexSegment) {
      const statusDraft = cloneDeep(statusSegment.value)
      statusDraft[indexFlight][indexSegment] = value
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setStatusClassBooking`, statusDraft)
    }

    // NOTE: => Tìm classBooking trong trip theo classBooking đã chọn
    // => nếu có, đổi luôn data của segment trên hành trình đó
    // => k có, show toast error.
    function handleChangeClassBooking(value, indexFlight, segment, indexSegment) {
      try {
        const segmentInSearchData = dataTrips.value[indexFlight]?.trips?.find(t => t.segments.find(s => s.tripIndex === segment.tripIndex && s.uniqueSegmentId === segment.uniqueSegmentId))
        const classAvailInSegment = segmentInSearchData.segments[indexSegment]?.bookingClassAvail?.find(c => c.code === value)
        if (classAvailInSegment) {
          handleSelectClassBooking(segment, classAvailInSegment)
        } else {
          const bookingClassCodeDraft = cloneDeep(bookingClassCodeSegment.value)
          // bookingClassCodeDraft[indexFlight][indexSegment] = bookingClassCodeDraft[indexFlight][indexSegment]
          store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setBookingClassCode`, bookingClassCodeDraft)
          toastError({ content: 'Hạng vé đã chọn không có sẵn trên chặng bay hiện tại, vui lòng chọn lại!' })
        }
      } catch (error) {
        toastError({ content: 'Lỗi xử lý dữ liệu, vui lòng thực hiện lại!' })
        console.error('An error in handleChangeClassBooking:', { error })
      }
    }

    return {
      isEmpty,
      dataTrips,
      formatCurrency,
      convertISODateTime,

      statusSegment,
      handleChangeStatus,

      classBookingOptions,
      bookingClassCodeSegment,
      handleChangeClassBooking,

      getSortTripBySegment,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.select_custom {
  ::v-deep .vs__dropdown-toggle {
    min-height: 37.98px !important;
    padding: 0px !important;

    .vs__search {
      width: 0px !important;
      padding: 0px !important;
    }

    .vs__selected {
      padding-left: .7rem !important;
      margin-top: 0 !important;
    }

    &.vs__open-indicator {
      margin: 0px !important;
    }
  }

  .flight_search {
    background-color: #166987 !important;
    color: white !important;
  }
}
</style>
